<template>
  <div class="page-download-guide" :class="type == 'ios_false' ? 'hidden' : ''">
    <div class="appstore-banner" :class="type">
      <img :src="logo" alt="" class="logo" />
      <h3 class="appname">{{ name }}</h3>
      <div class="button-content">
        <div class="btn" @click="downIos">{{ buttonName }}</div>
      </div>
    </div>
    <div class="appstore-content">
      <div>
        <div class="m-title red">请使用苹果safari浏览器访问</div>
        <div class="m-title">轻量版App安装教程</div>
        <div class="content-block">
          <div class="list-title">第一步</div>
          <div class="title">
            点击「轻量版App下载」按钮，
            <br />
            点击「允许」下载配置描述文件，
            <br />
            提示已下载描述文件，点击「关闭」。
          </div>
          <div class="img-wrap">
            <img src="../assets/images/app/1.jpg" alt="" />
          </div>
          <!-- <div class="title">
            点击「轻量版App下载」按钮，
            <br />
            然后点击浏览器下方中间的分享图标
          </div>
          <div class="img-wrap">
            <img src="../assets/images/app/2.jpg" alt="" />
          </div> -->
        </div>
        <div class="content-block">
          <div class="list-title">第二步</div>
          <div class="title">
            点击“App名称”下方「点击跳转安装」按钮，
            <br />
            点击「允许」下载配置描述文件，
            <br />
            然后点击“App名称” 进入。
          </div>
          <div class="img-wrap">
            <img src="../assets/images/app/2.jpg" alt="" />
          </div>
        </div>

        <div class="content-block">
          <div class="list-title">第三步</div>
          <div class="title">
            点击右上角「安装」，
            <br />
            输入锁屏密码，
            <br />
            点击屏幕下方「安装」，
            <br />
            安装成功。返回桌面即可打开App。
          </div>

          <div class="img-wrap">
            <img src="../assets/images/app/3.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="zoom" v-if="type == 'ios_false'">
      <img src="../assets/images/app/go-safari.png" alt="" />
    </div>
    <footer :style="{ background: type.indexOf('ios') == -1 ? 'none' : '#f3f3ff' }">
      Copyright © 2023 {{ name }}
    </footer>
  </div>
</template>
<script>
// https://ler2y9l.minojiude.com/static/cert/embedded.mobileprovision
const versionVerify = () => {
  const str = navigator.userAgent.toLowerCase();
  const ver = str.match(/cpu iphone os (.*?) like mac os/);
  let version = 0;
  if (!ver) {
    return 0;
  }
  version = Number(ver[1].replace(/_/g, ".").split(".")[0]);
  return version;
};
export default {
  props: ["type", "name", "logo"],
  data() {
    return {
      iosFileUrl: config.iosFileUrl,
      buttonName: "轻量版App下载",
    };
  },
  methods: {
    downIos() {
      if (this.buttonName == "轻量版App下载") {
        this.buttonName = "下载中..";
        location.href = this.iosFileUrl;
        setTimeout(() => {
          this.buttonName = "点击跳转安装";
        }, 1000);
        return;
      }

      if (this.buttonName == "点击跳转安装") {
        const iosVersion = versionVerify();
        if (iosVersion >= 12 && iosVersion < 17) {
          location.href = "./redirect.mobileprovision";
        } else {
          alert('请前往设置>通用>VPN与设备管理,选择描述文件井点击安裝')
        }
      }
    },
  },
};
</script>

<style scoped>
.red {
  color: red !important;
  font-weight: bold;
}

.button-content {
  margin-top: 20px;
}

.btn {
  margin: 0 auto;
  font-size: 18px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: linear-gradient(90deg,
      rgba(16, 111, 255, 1),
      rgba(63, 207, 255, 1));
  box-shadow: 0px 2px 4px 0px rgba(255, 255, 255, 0.18);
  text-align: center;
  font-weight: 500;
}

.btn,
.btn a {
  width: 250px;
  height: 50px;
  line-height: 50px;
  color: #fff;
}

.btn a {
  display: block;
  text-decoration: none;
}

.zoom {
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.zoom img {
  width: 200px;
  position: fixed;
  right: 20px;
  top: 10px;
}

footer {
  /* position: absolute; */
  width: 100%;
  bottom: 30px;
  color: #999;
  font-size: 10px;
  text-align: center;
  padding-bottom: 30px;
  z-index: 1;
  margin-top: -1px;
}

.logo {
  border-radius: 15px;
  overflow: hidden;
  width: 100px !important;
  height: 100px !important;
}

.list-title {
  text-align: center;
  margin-bottom: 20px;
  color: #0066ed;
  font-size: 14px;
  margin-top: 15px;
}

.list-title::before {
  vertical-align: middle;
  display: inline-block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAAYCAMAAAAyNwimAAAANlBMVEUAAAAoU/8qVP8nTv8yWf89XP8rVP8rVf8pU/8sVf8pUv8rUf8rUP9VVf8tVP8qVP8qU/8rVf8LKWK4AAAAEnRSTlMATYAaDQg3ekorGRcRAyFtXEIlGIjLAAAA4ElEQVRIx3TRSQ7EIAxE0TKYeUhy/8v2phVZuPL2iA+FP833Vcp1Z8WntPNT65N3wqelc/Q+pi5YKuElCio1ebUEas34muaKVoJRGohRxaiDNvRo9Lcih0OGs+Ww4Wg86L8/OM31izNcf3QSAGgJTtHjbBWnHjusHp2+AEggBLCaEMczZyQmoIFSGEmoBGNFarmF2c5bqO0W9hR3oG4YWajsfsibuAJ1wXiEemCMSA2UQBUYVagKo0eq/0ZzC2geRDSPZJonU5pnNFoXFTQv7GhdXNO6wqF1lUmXSp/mzRYAT9kRgdtZ/NUAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  content: "";
  padding-left: 12px;
  width: 35px;
  height: 6px;
  background-size: contain;
  background-position: 100%;
  transform: rotate(180deg);
}

.list-title::after {
  vertical-align: middle;
  display: inline-block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAAYCAMAAAAyNwimAAAANlBMVEUAAAAoU/8qVP8nTv8yWf89XP8rVP8rVf8pU/8sVf8pUv8rUf8rUP9VVf8tVP8qVP8qU/8rVf8LKWK4AAAAEnRSTlMATYAaDQg3ekorGRcRAyFtXEIlGIjLAAAA4ElEQVRIx3TRSQ7EIAxE0TKYeUhy/8v2phVZuPL2iA+FP833Vcp1Z8WntPNT65N3wqelc/Q+pi5YKuElCio1ebUEas34muaKVoJRGohRxaiDNvRo9Lcih0OGs+Ww4Wg86L8/OM31izNcf3QSAGgJTtHjbBWnHjusHp2+AEggBLCaEMczZyQmoIFSGEmoBGNFarmF2c5bqO0W9hR3oG4YWajsfsibuAJ1wXiEemCMSA2UQBUYVagKo0eq/0ZzC2geRDSPZJonU5pnNFoXFTQv7GhdXNO6wqF1lUmXSp/mzRYAT9kRgdtZ/NUAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  content: "";
  padding-left: 12px;
  width: 35px;
  height: 6px;
  background-size: contain;
  background-position: 100%;
}

.content-block {
  background: #fff;
  border-radius: 7px;
  padding: 10px !important;
  margin-bottom: 20px !important;
}

.page-download-guide {
  min-height: 100vh;
  /* background: #0b152f; */
  /* background: linear-gradient(#03041c, #03041cdb); */
}

.page-download-guide.hidden {
  overflow: hidden;
}

.appstore-content {
  min-height: calc(100vh - 375px);
  padding: 20px 0;
}

.m-title {
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  margin-top: 10px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #14181f;
}

.appname {
  font-weight: 500;
}

.appstore-banner {
  /* background: url(../assets/images/hb/ios.png); */
  height: 375px;
  padding: 50px 0;
  background-image: linear-gradient(#03041c, #03041cdb);
}
</style>
