<template>
  <div class="page">
    <Ios
      :type="type"
      :logo="logo"
      :name="name"
      v-if="type.indexOf('ios') > -1 && isShow"
    />
    <Android
      :type="type"
      :logo="logo"
      :name="name"
      v-if="type == 'android' && isShow"
    />
    <Pc :type="type" :logo="logo" :name="name" v-if="type == 'pc'" />
  </div>
</template>

<script>
import Ios from "@/components/Ios";
import Android from "@/components/Android";
import Pc from "@/components/Pc";
import { typeFn } from "../utils";


export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  components: {
    Ios,
    Android,
    Pc,
  },
  head() {
    return {
      title: this.name,
    };
  },
  data() {
    return {
      isShow: true,
      baseUrl: config.baseUrl,
      type: typeFn,
    };
  },
  computed: {
    name() {
      return this.$store.state.appName;
    },
    logo() {
      return this.$store.state.logo;
    },
  }
};
</script>

<style>
</style>